import {graphql} from "gatsby"
import React, { useEffect } from "react"
import Layout from "../Page"
import ContactUs from "../../components/ContactUs"
import config from "../../config/config"
import { getUser, isLoggedIn } from "../../services/auth"
import axios from "axios";

const UserDefinedForm = ({ data: { silverStripeDataObject }}) => {
    const host = config.host
    const { title, content, canViewType, showInSearch } = silverStripeDataObject.SilverStripeSiteTree;
    var validPermissions = ['Anyone', 'Inherit'];
    if(!validPermissions.includes(canViewType)){
        if(!isLoggedIn()) {
            if (typeof window !== 'undefined') {
                window.location.href = "/login/";
            }
        }
    }
    const {htmlForm} = silverStripeDataObject.SilverStripeUserDefinedForm
    const { bannerTitle, bannerSubTitle, bannerImageURL, metaTags} = silverStripeDataObject.Page;
    const pageID = silverStripeDataObject.silverstripe_id
    const pageData = {
        pageClass: 'ContactPage', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,      
    };

    // custom event listeners
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleSubmit = e => {
                e.preventDefault();

                const formData = new FormData(e.target);
                formData.append('PageID', pageID);
                axios.post(config.host + "/tribecaapi/userform/", formData).then(response => {
                    let _response_data = response.data;
                    if(_response_data.Success == 1){
                        if(_response_data.RedirectLink){
                            if (typeof window !== 'undefined') {
                                window.location.href = _response_data.RedirectLink;
                            }
                        }
                    }
                }).catch(function (error) {
                });
            }

            const form = window.document.getElementsByTagName('form')[0];
            form.addEventListener('submit', e => handleSubmit(e), false);
            const search = typeof window !== "undefined" ? window.location.search : "/"
            const params = new URLSearchParams(search)
            params.forEach(function(value, key) {
                let hidden_field = document.getElementsByName(key)
                if(hidden_field.length > 0){
                    hidden_field[0].value = value
                }
            });
            
            // clean up event
            return form.removeEventListener('submit', e => handleSubmit(e));
        }
        return () => {}
    }, [])

    return (                
        <Layout pageData={pageData} title={title}>
            <section className="section">
                <div className="container">
                    <h2 className="title no-underline text-center">{title}</h2>
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div dangerouslySetInnerHTML={{__html: htmlForm}} />
                        </div>
                    </div>
               </div>
            </section>
            {/* <ContactUs /> */}
        </Layout>
    );
};

export default UserDefinedForm

export const pageQuery = graphql`
    query ($link: String!) {
        silverStripeDataObject(link: { eq: $link }) {
            silverstripe_id
            SilverStripeSiteTree {
                title
                content
                canViewType
                showInSearch
            },
            Page{
                bannerTitle
                bannerSubTitle
                bannerImageURL
                metaTags
            },
            SilverStripeUserDefinedForm {
              htmlForm
            }
        }
    }
`;